<template>
  <div class="table">
    <div class="select">
      <selection @selectValue="getSelectVal"/>
      <el-button @click="submit">查询</el-button>
      <el-button @click="exportReports">导出</el-button>
    </div>
    <my-table :tableInfo="tableInfo" :colConfigs="colConfigs" @currentPage="currentPage">
      <el-table-column align="center" slot="option" label="操作">
        <template slot-scope="{row}">
          <el-button
            icon="iconfont icon-chakan-copy1"
            @click="checkStatus(row)"
          >查看处理
          </el-button>
        </template>
      </el-table-column>
    </my-table>
  </div>
</template>

<script>
import { exportListReports, reportList } from '@/api'
import MyTable from '@/components/MyTable'
import Selection from '@/components/Selection'
import { exportFun } from '@/utils/params'

export default {
  name: 'list-ting',
  components: {
    MyTable,
    Selection
  },
  async created () {
    await this.renderTable()
  },
  data () {
    return {
      isAll: true,
      tableInfo: {},
      requestParams: {},
      selectValue: [],
      optionsProps: {
        value: 'id',
        label: 'name',
        children: 'children',
        checkStrictly: true
      },
      colConfigs: [
        {
          prop: 'id',
          label: '序号',
          width: '50px'
        },
        {
          prop: 'created_at',
          label: '报事时间',
        },
        {
          prop: 'user_info.name',
          label: '报事人'
        },
        {
          prop: 'company_info.name',
          label: '总包部'
        }, {
          prop: 'department_info.name',
          label: '项目部门'
        },
        {
          prop: 'sentry_info.name',
          label: '所在监督岗'
        },
        {
          prop: 'type',
          label: '报事处理状态'
        },
        { slot: 'option' },
      ],
    }
  },
  methods: {
    currentPage (current) {
      this.page = current
      this.renderTable()
    },
    exportReports () {
      const param = {
        type: 1,
        company_id: this.selectValue[0] || '',
        department_id: this.selectValue[1] || '',
        sentry_id: this.selectValue[2] || '',
        group_id: localStorage.getItem('group_id'),
      }
      exportFun(exportListReports, param, 'exportListReports')
    },
    async renderTable () {
      const res = await reportList({
        ...this.requestParams,
        page: this.page
      })
      this.tableInfo = res.data.data
    },
    async submit () {
      let requestData = {}
      if (!this.isAll) {
        requestData = {
          company_id: this.selectValue[0] || '',
          department_id: this.selectValue[1] || '',
          page: 1
        }
      } else {
        requestData = {
          group_id: localStorage.getItem('group_id')
        }
      }
      this.requestParams = requestData
      await this.renderTable(requestData)
    },
    getSelectVal (val) {
      if (val[0] === 'all') {
        this.isAll = true
      } else {
        this.selectValue = val
        this.isAll = false
      }
    },
    checkStatus ({
      user_info,
      id
    }) {
      let user_name = user_info.name
      this.$router.push({
        path: '/list/list-detail',
        query: {
          name: user_name,
          id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.table {
  padding: 20px;
  box-sizing: border-box;
}

.select {
  display: flex;
  margin-top: -20px;
  margin-bottom: 10px;

  ::v-deep .el-select {
    margin-right: 10px;
  }

  button {
    background-color: red;
    color: #fff;
    padding: 0 20px;
    margin-left: 30px;
  }
}

.table {
  margin-top: 50px;
}

button {
  padding: 7px 25px;
  background: #fde5e4;
  border-radius: 4px;
  border: 0;
  font-size: 15px;
  font-family: PingFang SC;
  color: #e32322;
}

</style>
