<template>
  <el-cascader
    v-model="selectValue"
    :options="options"
    :props="optionsPros"
    separator="-"
    @change="change"
  />
</template>

<script>
import {getAllChildrenList} from '@/api'
export default {
  name: 'index.vue',
  async created () {
    try{
      const res = await getAllChildrenList({
        type:1
      });
      const data = res.data.data;
      this.setSelectData(data);
    }catch (e) {
    }
  },
  data(){
    return {
      options:[],
      selectValue:"",
      optionsPros:{
        value:'id',
        label:'name',
        children:'children',
        checkStrictly: true
      }
    }
  },
  methods:{
    change(value){
      this.$emit("selectValue",this.selectValue)
    },
    setSelectData(data){
      let selectArr = data.company.map(item => {
        if(item.department && item.department.length > 0) {
          item.children = item.department
          if(this.isDeep){
            item.department.map(d => {
              if(d.sentry && d.sentry.length > 0) {
                d.children = d.sentry
              }
            })
          }
        }
        return item;
      })
      selectArr.unshift({
          name:'全部',
          id:"all"
      })
      console.log(selectArr)
      this.options = selectArr
    },
  },
  watch:{
    opPros(val){
      this.optionsPros = val
    }
  },
  props:{
    isDeep:{
      type:Boolean
    },
    opPros:{
      type:Object
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-input__inner{
  width: 300px;
}
</style>
